import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: resolve => {
      require(['@/views/home/index.vue'], resolve)
    }
  },
  {
    path: '/sobre_nosotros',
    name: 'HowtoApply',
    meta: {
      title: '贷款'
    },
    component: resolve => {
      require(['@/views/loan/index.vue'], resolve)
    }
  },
  // {
  //   path: '/about_us',
  //   name: 'AboutUs',
  //   meta: {
  //     title: '关于我们'
  //   },
  //   component: resolve => {
  //     require(['@/views/aboutUs/index.vue'], resolve)
  //   }
  // },
  // {
  //   path: '/how_it_works',
  //   name: 'HowItWorks',
  //   meta: {
  //     title: '如何运行'
  //   },
  //   component: resolve => {
  //     require(['@/views/howItWorks/index.vue'], resolve)
  //   }
  // },
  {
    path: '/preguntas_frecuentes',
    name: 'Faq',
    meta: {
      title: '常见问题'
    },
    component: resolve => {
      require(['@/views/faq/index.vue'], resolve)
    }
  }
  // {
  //   path: '/User_experience',
  //   name: 'UserExperience',
  //   meta: {
  //     title: '联系我们'
  //   },
  //   component: resolve => {
  //     require(['@/views/contactUs/index.vue'], resolve)
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
